import React, { Component } from 'react';
import posts from '../../../sample-posts';
import Nav from '../../../components/Nav/Nav';
import Box from '../../../components/Box/Box';
import Text from '../../../components/Text/Text';
import LinkAnchor from '../../../components/Links/LinkAnchor';
// import LinkAnchor from '../../../components/Links/LinkAnchor';
// import Flex from '../../../components/Flex/Flex';
// import Image from '../../../components/Image/Image';

class remeshPage extends Component {
  state = {
    posts: posts,
    isActive: true
  };

  componentDidMount() {
    const historyState = this.props.location.state;
    if (historyState && historyState.loggedIn) {
      return;
    }
    this.props.history.push('/secureRemesh');
  }

  render() {
    // const { img } = this.state.posts.monster;

    return (
      <React.Fragment>
        <Box pt={3} px={[2, 2, 5]} mb={5}>
          <Box>
            <Nav active={this.state.isActive} />
          </Box>
          <Text
            as="h1"
            variant="body"
            fontSize={6}
            fontWeight="bold"
            color="green.0"
            my={4}
          >
            Remesh
          </Text>
          <Box measureWide>
            <Text as="h2" variant="body" fontSize={4} fontWeight="bold" mb={2}>
              My role
            </Text>
            <Text as="p" variant="body" style={{ display: 'inline' }}>
              <LinkAnchor
                variant="default"
                href="https://www.remesh.ai/"
                target="blank"
              >
                Remesh
              </LinkAnchor>{' '}
              allows organizations to have a live conversation with their target
              audience at scale, using AI to analyze and organize responses in
              real-time. I was hired as their Senior Product Designer to work on
              the core live conversation experience. Here I partnered with
              engineers, data scientists, PMs, and other designers to find
              opportunities, test and launch features.
            </Text>
          </Box>
          <Box mt={4}>
            <Text
              as="h2"
              variant="body"
              measureNarrow
              fontSize={4}
              fontWeight="bold"
              lineHeight="title"
              mb={2}
            >
              Implementing a more intuitive<br></br>ranking feature
            </Text>
            <Text as="p" variant="body" measureWide>
              Asking participants to rank a list of items is common for our
              customers. It helps them understand the priority of choices
              relative to each other. We didn’t have a simple way for our
              customers to accomplish this. Instead, they had to hack together a
              sequence of single-select or multi-select polls and do their best
              to work around the data quality and formatting issues that come
              out the other end. This tiring process affected our customers and
              their participants. Participants were getting fatigued answering
              these sequences of polls, which would impact their engagement in
              the conversation.
            </Text>
            <Box mt={4}>
              <Text
                as="h2"
                variant="body"
                measureNarrow
                fontSize={4}
                fontWeight="bold"
                lineHeight="title"
                mb={2}
              >
                Identifying the Problem
              </Text>
              <Text as="p" variant="body" measureWide>
                Before I get into any designs or research, let’s take a moment
                to talk about an important question,{' '}
                <span style={{ fontStyle: 'italic', fontWeight: 600 }}>
                  how do we even prioritize and consider working on this
                  problem?
                </span>{' '}
                Here are a few core insights that made the build of this feature
                top priority:
              </Text>

              <Box measureWide>
                <ul
                  style={{
                    paddingLeft: '32px',
                    paddingRight: '32px',
                    marginTop: '16px'
                  }}
                >
                  <li style={{ listStyleType: 'disc' }}>
                    At least $1.5 million worth of Revenue represented in
                    feedback would bring up ranking as a pain point
                  </li>
                  <li style={{ listStyleType: 'disc' }}>
                    25% of the conversations our customers success team helps
                    facilitate require ranking
                  </li>
                  <li style={{ listStyleType: 'disc' }}>
                    60% of the conversations our in-house research team builds,
                    runs, and analyzes require ranking
                  </li>
                </ul>
              </Box>
              <Text as="p" variant="body" measureWide marginTop={2}>
                This problem was affecting a large portion of the business, and
                if solved, would have a sizable positive impact.
              </Text>
            </Box>
            <Box mt={4}>
              <Text
                as="h2"
                variant="body"
                measureNarrow
                fontSize={4}
                fontWeight="bold"
                lineHeight="title"
                mb={2}
              >
                Process
              </Text>
              <Text as="p" variant="body" measureWide>
                I’m a strong advocate for user research and interviews
                throughout the entire design process, resulting in:
              </Text>
              <Box measureWide>
                <ul
                  style={{
                    paddingLeft: '32px',
                    paddingRight: '32px',
                    marginTop: '16px'
                  }}
                >
                  <li style={{ listStyleType: 'disc' }}>
                    Scheduling workshops with our in-house research team, our
                    customer success team, and customers to get a better idea of
                    how they’re currently approaching ranking on our platform
                  </li>
                  <li style={{ listStyleType: 'disc' }}>
                    Listening to customer calls discussing ranking
                  </li>
                  <li style={{ listStyleType: 'disc' }}>
                    Shadowing our in-house researchers and customer success team
                    as they build and run conversations
                  </li>
                  <li style={{ listStyleType: 'disc' }}>
                    Testing designs and prototypes with our in-house research
                    team and customers
                  </li>
                </ul>
              </Box>
            </Box>
          </Box>
          <Box mt={4}>
            <Text
              as="h2"
              variant="body"
              measureNarrow
              fontSize={4}
              fontWeight="bold"
              lineHeight="title"
              mb={2}
            >
              Pain Points, Obstacles, Objectives, and Goals
            </Text>
            <Text as="p" variant="body" measureWide>
              While there will always be an endless number of features we could
              build, we’re able to narrow down the scope to a few core pain
              points, obstacles, objectives, and goals.
            </Text>
            <Text
              as="p"
              variant="body"
              marginTop={2}
              measureWide
              fontWeight="bold"
            >
              Pain Points
            </Text>
            <Box measureWide>
              <ul
                style={{
                  paddingLeft: '32px',
                  paddingRight: '32px',
                  marginTop: '8px'
                }}
              >
                <li style={{ listStyleType: 'disc' }}>
                  Customers have to hack together a sequence of multi-select or
                  single-select polls and do their best to work around the data
                  quality and formatting issues
                </li>
                <li style={{ listStyleType: 'disc' }}>
                  Participants were getting fatigued answering these sequences
                  of polls, which would impact their engagement in the
                  conversation
                </li>
              </ul>
            </Box>
            <Text
              as="p"
              variant="body"
              marginTop={2}
              measureWide
              fontWeight="bold"
            >
              Obstacles
            </Text>
            <Box measureWide>
              <ul
                style={{
                  paddingLeft: '32px',
                  paddingRight: '32px',
                  marginTop: '8px'
                }}
              >
                <li style={{ listStyleType: 'disc' }}>
                  An absolute and average number can be misleading. We needed
                  additional data to make sense of ties or close averages
                  without sacrificing simplicity.
                </li>
                <li style={{ listStyleType: 'disc' }}>
                  We only had six weeks to work on this MVP with one designer
                  and a small team of distributed (newly hired) developers
                </li>
              </ul>
            </Box>
            <Text
              as="p"
              variant="body"
              marginTop={2}
              measureWide
              fontWeight="bold"
            >
              Objectives
            </Text>
            <Box measureWide>
              <ul
                style={{
                  paddingLeft: '32px',
                  paddingRight: '32px',
                  marginTop: '8px'
                }}
              >
                <li style={{ listStyleType: 'disc' }}>
                  Allow customers to have a more efficient and clear signal of
                  what is the top 3-6 preferences of their target audience
                </li>
                <li style={{ listStyleType: 'disc' }}>
                  Give participants a much simpler way to rank their preferences
                </li>
              </ul>
            </Box>
            <Text
              as="p"
              variant="body"
              marginTop={2}
              measureWide
              fontWeight="bold"
            >
              Goals
            </Text>
            <Box measureWide>
              <ul
                style={{
                  paddingLeft: '32px',
                  paddingRight: '32px',
                  marginTop: '8px'
                }}
              >
                <li style={{ listStyleType: 'disc' }}>
                  Increase ranking adoption across 25% of conversations
                </li>
                <li style={{ listStyleType: 'disc' }}>
                  Decrease in days between conversations from 36 days to 15 days
                </li>
                <li style={{ listStyleType: 'disc' }}>
                  10% increase in Month 0 to Month 1 retention
                </li>
              </ul>
            </Box>
            <Box mt={4}>
              <Text
                as="h3"
                variant="body"
                measureNarrow
                fontSize={3}
                fontWeight="bold"
                lineHeight="title"
                mb={2}
              >
                Adding a Ranking Question
              </Text>
              <Text as="p" variant="body" measureWide>
                Imagine you’re working on deploying three new employee benefits,
                or you’re going to develop hi-fidelity packaging mock-ups for
                the top 2 of 6 brand concepts. Before, you had to create
                multiple single-select or multi-select polls. Ask your
                participants to select all that are “must-haves” or “most
                important” in one question. Then another question for “nice to
                have” and another for “do not need”; the experience is less
                efficient and harder to evaluate for the customer, and draining
                for the participant.
              </Text>
              <Text as="p" variant="body" measureWide marginTop={2}>
                With the introduction of a Ranking Question, customers now have
                a clear & simple way to understand how participants would rank a
                set of options relative to each other.
              </Text>
              <Box mt={3} maxWidth={9}>
                <div
                  style={{
                    position: 'relative',
                    paddingBottom: '62.5%',
                    height: '0'
                  }}
                >
                  <video
                    title="Ranking-adding/deleting a list item"
                    controls
                    src="https://danielcardeans-link.s3.us-east-1.amazonaws.com/Danny+Portfolio/remesh/Rank-adding_deleting+a+rank.mp4"
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%'
                    }}
                  ></video>
                </div>
              </Box>
            </Box>
            <Box mt={4}>
              <Text
                as="h3"
                variant="body"
                measureNarrow
                fontSize={3}
                fontWeight="bold"
                lineHeight="title"
                mb={2}
              >
                Analyzing a Ranking Question
              </Text>
              <Text as="p" variant="body" measureWide>
                Before, it was hard for our customers to gauge what preferences
                mattered. What was the clear winner, if any? The data that came
                out the other end was all over the place and clunky. They would
                spend hours in excel trying to make sense of it and left
                wondering if it was correct.
              </Text>
              <Text as="p" variant="body" measureWide marginTop={2}>
                Now customers can get a clear sense of the “winners podium”
                (gold, silver, bronze). Arriving at this conclusion wasn’t as
                straightforward as it seems. Just because something comes in at
                #1 doesn’t necessarily mean it should be the only thing that
                matters. We wanted to be as transparent as possible with the
                analytics we provided our customers without sacrificing
                simplicity.
              </Text>
              <Text as="p" variant="body" measureWide marginTop={2}>
                We worked with our data scientist to figure out what information
                was valuable and how we could surface it. We decided to show an
                absolute & average rank number, a distribution of how
                participants ranked each option, and segments of the
                participant's demographics.
              </Text>
              <Box mt={3} maxWidth={9}>
                <div
                  style={{
                    position: 'relative',
                    paddingBottom: '62.5%',
                    height: '0'
                  }}
                >
                  <video
                    title="Ranking-adding/deleting a list item"
                    controls
                    src="https://danielcardeans-link.s3.us-east-1.amazonaws.com/Danny+Portfolio/remesh/Rank-Live+Conversation+view.mp4"
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%'
                    }}
                  ></video>
                </div>
              </Box>
            </Box>
            <Box mt={4}>
              <Text
                as="h3"
                variant="body"
                measureNarrow
                fontSize={3}
                fontWeight="bold"
                lineHeight="title"
                mb={2}
              >
                Participant’s Interaction with a Ranking Question
              </Text>
              <Text as="p" variant="body" measureWide>
                Before participants had to answer a sequence of multi-select or
                single-select questions with similar wording, this would take a
                toll on participants and affect their engagement in the
                conversation.
              </Text>
              <Text as="p" variant="body" measureWide marginTop={2}>
                Now participants get a simple question with a clear ranking
                experience and can view how their choices stack up with all
                other participants.
              </Text>
              <Box mt={3} maxWidth={9}>
                <div
                  style={{
                    position: 'relative',
                    paddingBottom: '62.5%',
                    height: '0'
                  }}
                >
                  <video
                    title="Ranking-adding/deleting a list item"
                    controls
                    src="https://danielcardeans-link.s3.us-east-1.amazonaws.com/Danny+Portfolio/remesh/Rank-participant+view.mp4"
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%'
                    }}
                  ></video>
                </div>
              </Box>
            </Box>
            <Box mt={4}>
              <Text
                as="h3"
                variant="body"
                measureNarrow
                fontSize={3}
                fontWeight="bold"
                lineHeight="title"
                mb={2}
              >
                Outcomes
              </Text>
              <Text as="p" variant="body" measureWide>
                Upon release, this feature gained healthy adoption. After month
                one of shipping the feature, we saw a 10% increase in adoption.
                The following months we were averaging out between 30% and 35%,
                above our target goal of 25%. By the third month we saw a
                decrease of days between conversation from 36 days to 24 day.
                Our retention from month 0 to month 1 was 6%.
              </Text>
              <Text as="p" variant="body" measureWide marginTop={2}>
                There were a few things cut from scope that I would have loved
                to follow up on and build. For example, being able to rank
                images. But we decided that the core of this feature was ranking
                a list of items and it’s what we focused on in the short
                six-week time frame we had. Overall, this feature gained healthy
                adoption and we felt great about what we accomplished.
              </Text>
            </Box>
          </Box>
        </Box>
      </React.Fragment>
    );
  }
}

export default remeshPage;
